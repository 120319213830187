<script setup lang="ts">
import DesktopNavbar from '@/layouts/desktop/components/desktop-navbar/DesktopNavbar.vue';
import DesktopFooter from '@/layouts/desktop//components/desktop-footer/DesktopFooter.vue';
import DesktopFeedback from '@/components/desktop-feedback/DesktopFeedback.vue';
import ChatButton from '@/components/chat-button/ChatButton.vue';

const route = useRoute();

const isSticky = computed(() => route.meta.isHeaderSticky);
const noFeedback = computed(() => route.meta.noFeedback);
</script>

<template>
  <div
    class="desktop-layout"
    :class="{ 'sticky-header': isSticky }">
    <div class="desktop-layout__header">
      <DesktopNavbar />
    </div>
    <div class="desktop-layout__content">
      <div class="desktop-layout__body">
        <slot />
      </div>
    </div>
    <DesktopFeedback v-if="!noFeedback" />
    <DesktopFooter />
    <div class="desktop-layout__chat-button">
      <ChatButton />
    </div>
  </div>
</template>

<style lang="scss" src="./style.scss" />
